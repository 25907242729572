import * as styles from './Changelog.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import * as Typography from 'components/typography'

import { HistoricalRenders } from './historical-renders/HistoricalRenders'
import { Notes } from './Notes'

interface Props {
  logs: {
    id: string
    body: string
    frontmatter: {
      date: string
      cubeCount: number
      formattedDate: string
    }
    wordCount: {
      words: number
    }
  }[]
}

export const Changelog: React.FC<Props> = (props) => {
  const { logs } = props

  return (
    <div>
      <div className={styles.header}>
        <h1>
          <Typography.PrimaryHeading>
            Cube Map Changelog
          </Typography.PrimaryHeading>
        </h1>
        <div className={styles.authors}>
          Maintained by Jett Crowdis and{' '}
          <a href="https://twitter.com/ahmattox">Anthony Mattox</a>
        </div>
        <Link className={styles.mapLink} to="/resources/cube-map/">
          View Cube Map
        </Link>
      </div>

      <Typography.Paragraph>
        The geography of the Cube Map evolves over time. Islands big and small
        emerge, drift, and divide as the cube landscape itself evolves and the
        number of cubes grows.
      </Typography.Paragraph>

      <div className={styles.historicalRenders}>
        <HistoricalRenders logs={logs} />
      </div>

      <Typography.Paragraph>
        Since we first published the{' '}
        <a href="/resources/cube-map/">Cube Map More</a> the number of cubes has
        more than tripled. Different input yields different results. Major
        themes create features like the Legacy/Vintage, Peasant, and Pauper
        contents have remained present but their relative positions meander.
        Tons of new, smaller islands have emerged as the Cube community has
        gotten more creative.
      </Typography.Paragraph>

      <Typography.Paragraph>
        The layout of the map is also, to a point, somewhat arbitrary.
        Compressing 25,000+ dimensions down to just two loses some information.
        There may be many equally valid projections so small shifts in data can
        have large impacts.
      </Typography.Paragraph>

      <Typography.HorizontalRule />

      <Typography.SecondaryHeading>
        Notes on Select Updates
      </Typography.SecondaryHeading>

      <Notes logs={logs} />
    </div>
  )
}
